export function formatDate(fmt, date) { // author: meizz
    const o = {
      'M+' : date.getMonth() + 1,
      'd+' : date.getDate(),
      'h+' : date.getHours(),
      'm+' : date.getMinutes(),
      's+' : date.getSeconds(),
      'q+' : Math.floor((date.getMonth() + 3) / 3),
      'S'  : date.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
}

export const Global = {
  // 组织架构白名单
  corporatesWhiteList: [
    1,	// 东方日升新能源股份有限公司
    145091389,	// 信息中心
    145091390,	// 集团财务中心
    145320574,	// 行政管理中心
    145322623,	// 信息管理部
    145330525,	// 义乌基地
    145358706,	// 常州基地
    145377344,	// 系统集成部
    145377358,	// 宁海总部
    145735885,	// 营销中心
    145755818,	// 东方日升（宁波）电力开发有限公司
    145801613,	// 人力资源中心
    145826301,	// 制造中心
    145826342,	// 审计部
    145831218,	// 证券部
    145854074,	// 常州斯威克光伏新材料有限公司
    145895777,	// 总裁办
    146805828,	// 信息化领导小组
    147589747,	// 常州项目i2431
    148725941,	// erp组
    148725942,	// mes组
    150961772,	// 研发中心
    150968798,	// pmc
    150978946,	// 财务部
    150981741,	// 总经办
    150993792,	// 综合管理中心
    151001961,	// 东方日升（常州）新能源有限公司
    151071720,	// ERP编码主数据小组
    151188045,	// 临时
    155770323,	// 双宇财务部
    155770324,	// 斯威克财务部
    155770325,	// 双一力财务部
    155770326,	// 宁海基地财务部
    155770327,	// 义乌财务部
    155770328,	// 常州财务部
    155770329,	// 洛阳财务部
    155770330,	// 电力财务部
    155770331,	// 瑞升财务部
    155770332,	// 融资租赁财务部
    155770333,	// 澳洲财务部
    155770334,	// 香港财务部
    155947989,	// 财务部
    155959969,	// 运营管理中心
    155977236,	// 物流部
    156018502,	// 美国公司
    156031521,	// 浙江双宇电子科技有限公司
    156037436,	// 上海瑞升电力开发有限公司
    156042465,	// 法务部
    156049420,	// 采购部
    156052555,	// 电池四车间
    156054282,	// 资金管理部
    156058448,	// 东方日升新能源股份有限公司（基地）
    156064656,	// 生产计划部
    156067508,	// 工程技术部
    156070399,	// 销售管理部
    156071528,	// 财务分析部
    156072355,	// 双一力公司
    156075547,	// 财务部
    156076380,	// 总经办
    156080891,	// 财务部
    156081238,	// 质量及全球客户服务中心
    156086380,	// 企业事务部
    156087472,	// 计划部
    156089516,	// 预算核算部
    156091245,	// 电池研发中心
    156094492,	// 外围设备部
    156098315,	// 东方日升（洛阳）新能源有限公司
    156101750,	// 财务管理部
    156107553,	// 电芯制造部
    156108298,	// 销售技术支持与产品管理
    156115198,	// 运营计划部
    156115215,	// 香港公司
    156131122,	// 东方日升融资租赁有限公司
    156133583,	// PACK制造部
    156133596,	// 电池生产部
    156134023,	// 公司高层
    156136209,	// 质量部
    156141420,	// 宁海电池生产基地
    156141423,	// 电池技术部
    156141424,	// 电池外围部
    156148163,	// 计划采购部
    156154131,	// 采购部
    156161235,	// 母公司财务部
    156170181,	// 财务部
    156179431,	// 电池设备部
    156183099,	// 投资发展部
    156191112,	// 质量管理部
    156196378,	// 行政部
    156197022,	// 海外工程技术部（中国）
    156201274,	// 组件设备部
    156202294,	// 宁海组件生产基地
    156203030,	// 产品事业部
    156203866,	// EHS部
    156512552,	// 设备部
    156512553,	// 集团组件研发中心
    156563403,	// 质量部
    156572235,	// 综合管理部
    156572236,	// 海外工程交付部
    156572237,	// 电站运营部
    156572238,	// 开发管理部
    156615548,	// 总经办
    156683421,	// 总经办
    156695019,	// 组件制造
    156710954,	// 梅林生产部
    156744269,	// 电池质量部
    156744294,	// 东方日升（义乌）新能源有限公司
    156818545,	// 组件质量部
    156838447,	// 组件生产部
    156912388,	// 组件三车间
    156948241,	// 组件外围部
    156963005,	// 组件技术部
    156963027,	// 运营中心
    156977135,	// 仓储部
    157077985,	// 组件五车间
    157084970,	// NPI部
    157090840,	// 亚太区
    157093902,	// 东方日升（宁波）企业管理咨询有限公司
    157097840,	// 电池三车间
    157101840,	// 组件四车间
    157110743,	// 供应商管理
    157132744,	// 印度区
    157139626,	// 业务开发部
    157148773,	// 教务处
    157153928,	// 人力资源部
    157156521,	// 市场营销部
    157158722,	// 欧洲区
    157162542,	// 组件一车间
    157176767,	// 人力资源部
    157203549,	// 电池一车间
    157204399,	// 营销中心办公室
    157209870,	// 内训处
    157218407,	// 组件二车间
    157223741,	// 组件内控质量部
    157230917,	// 澳洲Australia
    157231400,	// 韩国 Korea
    157234764,	// 行政部
    157237958,	// 总务处
    157254403,	// 南美区
    157270203,	// 人事行政中心
    157277399,	// 质安部
    157280300,	// 教研处
    157285262,	// 实验室
    157295043,	// 电池二车间
    157307137,	// 产品设计部
    157326163,	// 开发部
    157353006,	// 日本Japan
    157374088,	// 经营计划部
    157399029,	// 财务部
    157405161,	// 材料研发部
    157451109,	// 认证及项目管理部
    159212586,	// 质量中心
    159251516,	// 综合管理部
    161011137,	// 总经办
    161040204,	// 科技园厂区生产部
    161128098,	// 物流计划部
    161128099,	// 财务部
    161128100,	// 设备部
    161128101,	// 动力部
    161128102,	// 采购部
    161128103,	// 总经办
    161128104,	// 技术部
    161128105,	// 质量部
    161128107,	// 生产部
    161167231,	// 灯具营销部
    161167249,	// 研发二部（LED灯具）
    161167251,	// 系统营销部
    163205059,	// 仓储部
    163205064,	// 电商部
    163205065,	// 工艺部
    163205066,	// 研发一部（太阳能灯具）
    163205067,	// 研发三部（光伏系统）
    163241026,	// 综合管理部
    163241058,	// 电子商务部
    163241099,	// 设备部
    163350048,	// 设施动力部
    163350051,	// 采购部
    163350054,	// 物流计划部
    163350056,	// 设备部
    163350058,	// 生产部
    163350062,	// 企业事务部
    163350064,	// 信息部
    163868628,	// 营销中心
    163935438,	// 市场部
    165535174,	// 中国区
    165583053,	// 技术工艺部
    165583054,	// 仓储部
    165583394,	// 营销中心.中国区管理群
    165583550,	// 财务部
    165646551,	// 财务部
    165646552,	// 采购&开发部
    165646553,	// 国内营销部
    165646554,	// 海外营销部
    165646558,	// 系统集成部
    165646559,	// 总经办
    165646568,	// 应用组
    165646571,	// EMEA大区
    165646572,	// 亚太区
    165653440,	// 综合管理部
    165655262,	// 分布式运维部
    165655266,	// 国内工程
    165655267,	// 售后工程部
    165655268,	// 国内开发部
    165655344,	// Risen AU
    165795807,	// 国内客服
    165980373,	// 海外客服
    166033258,	// 质量管理
    166861966,	// 宁海
    166932733,	// 义乌
    166996322,	// 洛阳
    167097203,	// 采购部
    167113041,	// 常州
    167540399,	// 融资交易部
    167558124,	// 国内渠道营销
    167558145,	// 西店生产部
    167813127,	// 招聘配置组
    167813132,	// 绩效薪酬组
    167828178,	// 行政部
    167828179,	// 人力资源部
    167828181,	// 人力资源共享服务中心（SSC）
    167828182,	// 人力资源专家中心（COE）
    167828183,	// 人力资源培训组
    167828184,	// 人力资源合作伙伴（HRBP）
    167878136,	// 电池制造中心101
    167878137,	// 电池生产101
    167878138,	// 电池工艺101
    167878139,	// 电池质量101
    167878141,	// 电池设备101
    167878143,	// EHS部
    167878144,	// 信息部
    167878145,	// 电池制造中心102
    167878146,	// 组件制造中心103
    167878148,	// 组件制造中心104
    167878155,	// 电池生产102南区
    167878156,	// 电池生产102北区
    167878157,	// 电池工艺102南区
    167878158,	// 电池工艺102北区
    167878159,	// 电池设备102南区
    167878160,	// 电池设备102北区
    167878161,	// 电池质量102
    167878162,	// 组件生产103
    167878163,	// 组件工艺103
    167878164,	// 组件设备103
    167878165,	// 组件生产104
    167878166,	// 组件工艺104
    167878167,	// 组件设备104
    167878168,	// 组件质量部
    167878174,	// 动力部
    167878175,	// 仓储部
    167878176,	// 物流部
    168686256,	// 东方日升（上海）中心
    168848124,	// 基建部
    170462456,	// 点点财务部
    170582623,	// 海外人力资源部
    170582624,	// 员工关系组
    170770024,	// PDI
    170770097,	// 东南亚 SEA
    170941042,	// 综合管理部
    170941043,	// 财务部
    170941044,	// 销售部
    182418116,	// Risen Spain
    194044029,	// 地面运维部
    194044030,	// 运营计划部
    194044031,	// 质量安全部
    194044032,	// 产品市场部
    194044035,	// 总经办
    232486205,	// 融资租赁
    232573401,	// 投融资部
    232760418,	// 业务开发部
    232902348,	// 财务部
    233537156,	// 风控法务部
    235706094,	// Ninghai office
    236046014,	// 商务处
    236190017,	// 行政部
    236619028,	// SA
    236619029,	// SEA I
    236619030,	// SEA II
    236619031,	// EMEA
    236619032,	// CA
    236619033,	// LATAM
    236619034,	// SPAIN
    236619035,	// Engineering Dept
    236619036,	// Construction Dept
    236619037,	// O&M Dept
    236619038,	// Development Dept
    236619039,	// Mexico office
    236619040,	// Vietnam office
    236619041,	// Kazakhstan office
    237194041,	// General Mgnt Dept
    237439833,	// 集团（信息中心）
    237735866,	// 集团（人事）
    238105750,	// 集团（财务）
    238107788,	// Risen Energy（American）
    238163828,	// Risen Energy(Japan)
    238232803,	// 集团（海外客服）
    238302853,	// Risen Energy(HongKong)
    238393732,	// 集团（海外）
    238411731,	// 集团（上海）
    238557735,	// 上海瑞升电力开发有限公司
    238697800,	// 国内
    238911024,	// Risen Energy(Marketing)
    243171981,	// 规划治理部
    246103024,	// 基础架构
    246103030,	// 运维
    246103031,	// 开发
    257722595,	// 运维（上海）
    258187268,	// 行政部
    258189272,	// 产品（上海）
    258344293,	// 财务（上海）
    262938051,	// 制造
    262938052,	// IE部
    269186046,	// 技术整合部
    289140895,	// 质量部
    289698664,	// 人力资源部
    290358028,	// 销售部
    303931632,	// 综合管理部
    303931633,	// 营销部
    303931634,	// 总经办
    303931635,	// 研发部
    303931636,	// PMC
    304169128,	// 电芯质量
    304259075,	// 电芯采购
    305187066,	// 销售组
    305340069,	// 电芯综合办
    306106077,	// 技术组
    306548086,	// PMC小组
    306566060,	// PACK质量
    306586091,	// 质量部
    306599063,	// 集成研发部
    306650047,	// 北美区
    306666067,	// 电芯财务
    322528495,	// 质量部
    322528496,	// 组件质量部
    325822714,	// 进贤电站
    325862751,	// 邹平
    325871584,	// 芮城宝升电站
    325876543,	// 高邮电站
    326085447,	// 菏泽
    326186514,	// 乌海电站
    326190470,	// 仙桃电站
    326197410,	// 蛇蟠涂电站
    326237563,	// 旭宁电站
    326355428,	// 池州电站
    326362409,	// 高邮振兴电站
    326597443,	// 铜鼓电站
    326622379,	// 陇县电站
    326670389,	// 余姚
    326694972,	// 湖北
    329995013,	// 计划部
    330224013,	// 采购部
    330326020,	// 品管部
    333534728,	// 国内电商部
    333754651,	// 集团（法务）
    336774362,	// 组件系统技术研发部
    336974473,	// 前沿技术开发部
    337029438,	// 马来西亚
    337314467,	// 东方日升集团总部运营中心运营计划部
    337314468,	// 组件研发中心
    337314470,	// NPI部
    337314471,	// 认证及项目管理部
    337314472,	// 前沿技术开发部
    339140051,	// 实验室
    339556271,	// 试验线
    339561302,	// 技术支持
    339943107,	// 销售一组
    340146026   // 项目工程部
  ]
};
